<template>
	<div id="liveList">
		<page-live-list ref="live" :is-shop="0"></page-live-list>
	</div>
</template>
<script>
import PageLiveList from '@/components/layout/live/page-live-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	name:"LiveList",
	components:{
		PageLiveList,
	},

	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/live/liveEdit' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
	
</script>

<style>
	
</style>
